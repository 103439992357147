.containerOpen {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    z-index: 10000000;
}

.containerOpenFirefox {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    background-color: rgba(102, 97, 97, 0.918);
    animation: fadeInForFirefox .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    z-index: 10000000;
}

.containerClose {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    visibility: hidden;
    animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    width: 1132px;
    height: auto;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    box-shadow: 2px 2px 2px 2px dimgray;
}

.smsDetailsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 42px;
}

.smsDetailsContainer > span {
    margin-right: 18px;
}

.smsDetailsContainer > p {
    font-size: 42px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: -0.67px;
    color: #333;
}

.smsDetailsContainer > p > span {
    margin-left: 10px;
    font-size: 42px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: -0.67px;
    color: #2aae5a;
}

.lineSeperator {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.qrContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 30px 10px 30px;
}

.textContainer {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.textContainer > p:nth-child(1) {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: -0.58px;
    color: rgba(112, 23, 141, 0.85);
    margin-top: 30px;
}

.textContainer > p:nth-child(2) {
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.51px;
    color: rgba(51, 51, 51, 0.85);
    width: 596px;
}

.cancelButtonContainer {
    position: absolute;
    right: 0;
    top: -40px;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .modalContainer {
        display: none !important;
    }
}

@keyframes fadeInForFirefox {
    0% {
        transform: scale(0);
    }
    100% {

        transform: scale(1);
    }
}


@keyframes fadeIn {
    0% {
        backdrop-filter: blur(0px);
        transform: scale(0);
    }
    100% {
        backdrop-filter: blur(30px);
        transform: scale(1);
    }
}

@keyframes fadeOut {
    0% {
        backdrop-filter: blur(30px);
        transform: scale(1);
    }
    25% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        backdrop-filter: blur(0px);
        transform: scale(0.5);
    }
}