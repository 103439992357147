.selectionContainer {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 75px;
    margin-right: 5px;
}

.countryCodeInput {
    position: absolute;
    top: 0;
    left: 0;
    margin-right: 5px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .375rem .75rem;
    font-weight: 400;
    color: #ffffff;
    border-radius: 5px;
    text-align: center;
    transform: skewX(-10deg);
    box-shadow: 0 8px 10px 5px rgb(0 0 0 / 25%);
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, .4);
    border-image-slice: 1;
}

.countryCodeInput > p {
    font-family: var(--font-primary);
    transform: skewX(10deg);
    margin: 0;
    font-size: 14px;
}

.countryCodeInput svg {
    position: absolute;
    right: 5px;
}

.countryDropDown {
    position: absolute;
    top: 114%;
    left: -8px;
    z-index: 100;
    border-radius: 4px;
    max-height: 26vh;
    width: 25vw;
    padding: 10px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 1);
    color: white;
}

.srchContainer {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
}

.srchBox {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    margin-bottom: 10px;
}

.srchBox::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.countries {
    max-height: 19vh;
    overflow: auto;
}

.countryElement {
    margin: 2px;
    border-radius: 2px;
}

.countryElement:hover {
    background: rgba(55, 92, 213, 0.51);
    cursor: pointer;
}

.selectionArrow {
    position: absolute;
    width: 10px;
    top: calc(50% - 5px);
    right: 5px;
}