.div {
    transition: transform 50ms;
}
@media (hover: hover) {
    .div:hover {
        transform: scale(1.1);
    }
}

.div:active {
    transform: scale(0.9);
}

@keyframes breathing {
    0% {
        transform: scale(0.95);
    }

    25% {
        transform: scale(1);
    }

    60% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}
