.buttonContainer {
    display: none;
}

.buttonContainer:hover {
    text-decoration: none;
}

.iosButtonContainer {
    display: flex;
    justify-content: center;
}

.iosButtonContainer > a > img {
    width: 100%;
}

.iconView {
    position: relative;
    width: 24px;
    height: 27px;
    transform: skewX(7deg);
}

.mainContainer {
    font-family: var(--font-primary);
    border-radius: 6px;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.5);
    position: relative;
    transform: skewX(-7deg);
    padding: 1px;
    margin: 0 5px;
    overflow: hidden;
}

.content {
    font-size: clamp(14px, 4.4vw, 85px);
    border-radius: 6px;
    padding: 6px;
    min-width: clamp(100px, 36.4vw, 700px);
    width: 100%;
    height: 100%;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    font-style: italic;
    letter-spacing: -0.32px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content p {
    margin: 0;
    transform: skewX(7deg);
}

.arrow3 {
    display: flex;;
}

.arrow3container span {
    height: 4px;
    width: 24px;
    display: block;
    overflow: hidden;
    background-color: #ffffff;
}

.svg {
    transition: transform 500ms;
    animation: rotate 300ms linear 300ms 1 forwards;
}

@keyframes a3 {
    0% {
        transform: translateY(-4px);
    }
    100% {
        transform: translateY(2px);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-270deg);
    }
}

@keyframes scale {
    0% {
        transform: scale(1);
        opacity: 0.2;
    }
    75% {
        transform: scale(2);
        opacity: 1;
    }
    100% {
        transform: scale(0.1);
        opacity: 0.2;
    }
}

@keyframes ellipsisAnimation {
    0% {
        clip-path: inset(0 8.5% 0 0);
    }
    25% {
        clip-path: inset(0 6% 0 0);
    }
    50% {
        clip-path: inset(0 3% 0 0);
    }
    75% {
        clip-path: inset(0 0 0 0);
    }
}


@-webkit-keyframes shimmerAnimation {
    0% {
        transform: translate(-1000px);
    }
    100% {
        transform: translate(1000px);
    }
}

@keyframes shimmerAnimation {
    0% {
        transform: translate(-1000px);
    }
    100% {
        transform: translate(1000px);
    }
}

@-webkit-keyframes placeholderShimmer2 {
    0% {
        background-position: -10rem 0;
    }
}

@keyframes placeholderShimmer2 {
    0% {
        background-position: -10rem 0;
    }
}

@media screen and (max-width: 767px) {
    .buttonContainer {
        display: block;
    }

    .fixedDownloadButtonContainer {
        display: block;
    }

    .iosButtonImage_FB {
        height: 42px;
    }

    .shimmer {
        width: 30%;
        background-size: 1rem 1rem;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shimmerAnimation;
        animation-timing-function: linear;
        background: linear-gradient(to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0)) no-repeat 0 0;
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        border-radius: 6px;
    }

    .shimmer2 {
        width: 100%;
        background-size: 10rem 1rem;
        animation-name: placeholderShimmer2;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background-image: linear-gradient(90deg,
        rgba(255, 255, 255, 0.1) 20%,
        transparent 20%,
        transparent 50%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.1) 70%,
        transparent 70%,
        transparent);
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        border-radius: 6px;
    }

}