.mySlides > * {
    transition: all 500ms;
}

.mySlides {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.mySlides::-webkit-scrollbar {
    display: none;
}

.child {
    min-width: calc(100%);
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

/* Slideshow container */
.slideshowContainer {
    width: 100%;
}

/* Next & previous buttons */
.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 35%;
    width: auto;
    padding: 12px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.prev {
    left: -15px;
}

/* Position the "next button" to the right */
.next {
    right: -15px;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: .4
    }
    to {
        opacity: 1
    }
}

@media screen and (max-width: 767px) {
    /* Next & previous buttons */
    .prev, .next {
        top: 45%;
    }

    .prev {
        left: 0;
    }

    .next {
        right: 0;
    }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
    .prev, .next, .text {
        font-size: 11px
    }
}