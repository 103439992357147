.mainContainer {
    font-family: var(--font-primary);
    position: relative;
}

.banner-background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.banner-background-image img {
    margin: 0;
    padding: 0;
    display: block;
    height: 100%;
    width: 100%;
    object-position: top center;
    object-fit: cover;
}

.bannerImageFg {
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: bottom center;
}

.bannerHeadlineImage {
    width: 100%;
    height: auto;
}

.innerContainer {
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.styledRow {
    height: 100% !important;
    flex-direction: row !important;
}

.mobileImageContainer {
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 540px;
    padding: 0;
}

.mobileImageContainer_FB {
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 499px;
    padding: 0;
}

.rightContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rightContainer_FB {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 199px;
    height: 100px;
    position: relative;
    margin-top: 10px;
}

.logoContainer_FB {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 199px;
    height: 100px;
    position: relative;
    margin-top: 10px;
}

.bannerText {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    position: relative;
    width: 100%;
    height: 266px;
    margin-top: 48px;
    margin-bottom: 15px;
}

.bannerText_FB {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    position: relative;
    width: 326px;
    height: 210px;
    margin-top: 48px;
    margin-bottom: 20px;
}

.mobileInputContainer {
    width: 100%;
    margin-bottom: 30px;
}

.inputContainer {
    display: flex;
    width: 100%;
    height: 45px;
    border-radius: 4.4px;
    margin-bottom: 13px;
}

.inputContainer .inputBox {
    height: auto;
    width: 100%;
    padding: .375rem .25rem;
    border-radius: 5px;
    transform: skewX(-10deg);
    box-shadow: 0 8px 10px 5px rgb(0 0 0 / 25%);
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, .4);
    border-image-slice: 1;
}

.inputBox input {
    height: 100%;
    width: 100%;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    transform: skewX(10deg);
    background: rgba(0, 0, 0, 0);
    border: none;
    text-transform: uppercase;
}

.inputContainer input::placeholder {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-size: 14px;
    opacity: 0.75;
}

.errorText {
    font-weight: 300;
    font-size: 16px;
    margin: 0 0 8px;
    color: white;
    text-align: center;
}

.smsButtonContainer {
    width: 97%;
}

.smsButtonContainer > button {
    padding: 0;
    width: 100%;
    height: 45px;
    background: rgba(177, 253, 43, 1);
    background: linear-gradient(to bottom, rgba(177, 253, 43, 1) 0%, rgba(49, 235, 111, 1) 100%);
    text-transform: uppercase;
    font-family: var(--font-primary);
    font-size: clamp(18px, 1.25vw, 24px);
    color: #1a1a1a;
    transform: skewX(-10deg);
    box-shadow: 0 8px 10px 5px rgb(0 0 0 / 25%);
    font-weight: 500;
    letter-spacing: -0.38px;
    border-radius: 8px;
    border: 0;
    outline: none;
}

.smsButtonContainer > button > p {
    background: linear-gradient(121.19deg, rgba(133, 239, 49, 0) 25.73%, rgba(255, 255, 255, .3) 45.27%, rgba(133, 239, 49, 0) 62.27%), rgba(255, 255, 255, 0);
    height: 100%;
    transform: skewX(10deg);
    padding: clamp(5px, 0.6vw, 12px) clamp(10px, 1vw, 20px);;
    margin: 0;
    animation-duration: 60s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    display: flex;
    align-items: center;
    justify-content: space-around;
    line-height: 1;
}

.withdrawalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.withdrawalContainer > p {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
}

.withdrawalContainer > div {
    display: flex;
    flex-direction: row;
    max-width: 400px;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 400px;
    height: 35px;
}

.fixedDownloadButtonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    -webkit-transition: .3s;
    transition: .3s;
    box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 8%);
    padding: 10px 20px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.hamburgerContainer {
    position: absolute;
    top: 25px;
    right: 15px;
    z-index: 200000;
}

@media screen and (max-width: 767px) {
    .mainContainer {
        padding: 0;
    }

    .innerContainer {
        padding: 0;
    }

    .styledRow {
        flex-direction: column-reverse !important;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0;
    }

    .rightContainer {
        margin-bottom: 10px;
    }

    .mobileImageContainer {
        min-height: 355px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .mobileImageContainer_FB {
        min-height: 314px;
        margin: 45px 0 0 0;
    }

    .rightContainer {
        margin: 55px 0 5px 0;
        min-height: 125px;
        padding: 0;
    }

    .logoContainer {
        width: 215px;
        height: 102px;
    }

    .logoContainer_FB {
        margin-top: 0;
        width: 215px;
        height: 70px;
    }

    .bannerText {
        width: 280px;
        height: 100px;
        margin: 0;
    }

    .bannerText_FB {
        display: none;
    }

    .smsButtonContainer {
        display: none !important;
    }

    .errorText {
        display: none;
    }

    .withdrawalContainer {
        display: none !important;
    }

    .mobileInputContainer {
        display: none;
    }
}

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -10000px 0
    }

    100% {
        background-position: 10000px 0
    }
}

@keyframes placeholderShimmer {
    0% {
        background-position: -10000px 0
    }

    100% {
        background-position: 10000px 0
    }
}